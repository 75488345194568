<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    :class="$route.path.includes('products') ? 'color-listing__wrapper2' : 'color-listing__wrapper'"
    :style="$route.path.includes('products') ? 'margin-top: 12px; width: 22%; display: flex; flex-direction: column; align-items: flex-start;' : 'margin-top: 59px; width: 15%; display: flex; flex-direction: column; align-items: flex-start;' "
    @click="removeLists"
  >
    <div
      :style="$route.path.includes('create-order') ? 'padding-bottom: 17px' : ''"
    >
      <div
        class="black"
      >
        <b-icon-search
          v-if="searchColor == ''"
          style="position: absolute; left: 5px; top: 12px"
        />
        <b-icon-x-circle
          v-else-if="searchColor != '' && !noSearch"
          class="search1"
          style="position: absolute; right: 20px; top: 12px; cursor: pointer"
          @click="searchColor = ''"
        />
        <input
          v-model="searchColor"
          :class="{'button--disabled': !materialId}"
          :disabled="!materialId"
          type="text"
          autocomplete="off"
          name="search-box"
          :placeholder="$t('SearchByColor')"
          :style="getFilteredDressColors.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
          @keyup="inputChanged"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
        >
      </div>
      <div
        v-if="getFilteredDressColors.length > 0 && noSearch"
        ref="scrollContainer"
        class="dropdrop"
        :style="getFilteredDressColors.length > 7 ? 'height: 210px' : 'height: auto'"
      >
        <div
          v-for="(result, index) in getFilteredDressColors"
          :key="index"
          ref="options"
          style="cursor: pointer"
          class="search-item"
          :class="{ 'is-active': index === arrowCounter }"
          @click="searchByOn(result);"
        >
          <p style="margin: 0">
            {{ result }}
          </p>
        </div>
      </div>
    </div>
    <button
      v-if="$route.path.includes('products')"
      v-b-modal.modal-add-color
      class="button"
      :class="{ 'button--hidden': createOrder, 'button--disabled': !materialId}"
      :disabled="!materialId"
    >
      <img
        src="../../assets/images/plus-circle.svg"
        alt="plus"
      >
      {{ $t('AddColor') }}
    </button>
    <!-- <div class="table__search-input">
      <b-icon-search />
      <input v-model="searchTerm" type="text" />
    </div> -->
    <!-- <hr class="mt-2 mb-2 mb-2"/> -->
    <div
      style="width: 100%;  padding-top: 16px; margin-top: -3px;"
      :class="{'button--disabled': !materialId}"
    >
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th
              :style="
                $route.path.includes('products')
                  ? 'font-size:15px;padding: 7px; border-radius: 0px 0px 0px 0px;  color:#262E6C !important;'
                  : 'font-size:15px;padding: 7px; border-radius: 0px 0px 0px 0px;  color:#262E6C !important;'
              "
            >
              {{ $t('Color') }}
            </th>
            <th
              v-if="$route.path.includes('products')"
            >{{ $t("Image") }}</th>
            <th
              v-if="$route.path.includes('products')"
            >
              {{ $t('Actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="color in getDressColors"
            :key="color.materialId"
            class="hover-row"
            style="cursor: pointer;"
            :style="classNameCh === color.colorName ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="
              matProps(color);
            "
          >
            <td style="padding: 10px">
              {{ color.colorName }}
            </td>
            <td style="padding: 9px;">
              <img
                v-b-tooltip="$t('ClickToViewPhoto')"
                style="height: 40px; border-radius: 3px; "
                :src="color.photos[0]"
                alt=""
                @click.stop="getAllImages(color)"
              >
            </td>

            <td
              class="actiontd"
              style="text-align: center;"
              @click="togglePopup(color.colorName)"
            >
              <img
                src="../../assets/images/actionDots.svg"
                alt=""
              >
              <div
                v-if="showPopup[color.colorName]"
                class="popup"
              >
                <div
                  v-if="$route.path.includes('products')"
                  v-b-modal.modal-edit-color
                  @click="edit(color),getAllImagesEdit(color)"
                >
                  <button class="actionEdit">
                    <b-icon-pencil />{{ $t('Edit') }}
                  </button>
                </div>
                <div
                  v-if="$route.path.includes('products')"
                  v-b-modal.modal-delete-color
                  @click.stop="deletee(color)"
                >
                  <button class="actionDelete">
                    <b-icon-trash />{{ $t('Delete') }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsForDressColors > 15"
          v-model="page"
          :total-rows="getTotalItemsForDressColors"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-itemiii"
          next-class="next-itemiii"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <vue-easy-lightbox
      :esc-disabled="false"
      move-disabled
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
    <AddColorModal
      :dress-name="materialId.name"
      @materials="addColor"
    />
    <EditDressColorModal
      :color="color"
      :dress-name="materialId.name"
      :image="image"
      @updateColor="editColor"
      @getPhotos="getAllImagesEdit"
    />
    <DeleteColorModal
      :color-name-modal="colorNameModal"
      @deleteColor="deleteColorModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import AddColorModal from '@/components/products/modals/AddColorModal.vue';
import EditDressColorModal from '@/components/products/modals/EditDressColorModal.vue';
import DeleteColorModal from '@/components/products/modals/DeleteColorModal.vue';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    AddColorModal,
    EditDressColorModal,
    VueEasyLightbox,
    DeleteColorModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['materialId', 'createOrder'],
  data() {
    return {
      showPopup: {},
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      colorNameModal: null,
      deleteColorId: null,
      initialId: '',
      searchColor: '',
      noSearch: false,
      status: true,
      classNameCh: '',
      page: 1,
      pageSize: 15,
      searchTerm: '',
      arrowCounter: 0,
      rows: [],
      materialObject: {},
      editMat: {},
      color: {
        mainDressCategoryColorId: '',
        name: '',
        hasPhotos: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getDressColors', 'getFilteredDressColors', 'getImages', 'getTotalItemsForDressColors']),

  },
  watch: {
    materialId() {
      this.classNameCh = '';
    },
    page(value) {
      this.loadDressColorsById({
        mainDressCategoryId: this.materialId.id, pageNumber: value, pageSize: this.pageSize,
      })
    },
    searchColor(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadDressColorsById({
            mainDressCategoryId: this.materialId.id, pageNumber: this.page, pageSize: this.pageSize,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressColorsByName({ mainDressCategoryId: this.materialId.id, name: this.searchColor });
          this.noSearch = true
        }
      }, 500);
    },
  },
  methods: {
    ...mapActions([
      'filteredDressColorsByName',
      'resetFilteredDressColors',
      'addColorToMainDressCategoryById',
      'editColorToMainDressCategoryById',
      'loadDressColorsById',
      'inventoryProductSizeFromColorId',
      'resetProductsByArticleNumber',
      'getImagesByMainDressCategoryColorId',
      'resetCustomOrders',
      'changeLoadingtoTrue',
      'deleteColorDress',
      'loadDressesPagination',

    ]),
    deletee(item) {
      this.deleteColorId = item.mainDressCategoryColorId
      this.colorNameModal = item.colorName
    },
    async deleteColorModal() {
      await this.deleteColorDress({
        id: this.deleteColorId,
        successCallback: () => {
          this.loadDressColorsById({ mainDressCategoryId: this.materialId.id, pageNumber: this.page, pageSize: this.pageSize })
        },
      })
    },
    edit(item) {
      this.color.mainDressCategoryColorId = item.mainDressCategoryColorId
      this.color.name = item.colorName
      this.$bvModal.show('modal-edit-color');
    },
    async editColor(obj) {
      await this.editColorToMainDressCategoryById({
        object: obj,
        successCallback: () => {
          this.loadDressColorsById({ mainDressCategoryId: this.materialId.id, pageNumber: this.page, pageSize: this.pageSize })
        },
      })
    },
    async addColor(obj) {
      obj.mainDressCategoryId = this.materialId.id;
      await this.addColorToMainDressCategoryById({
        object: obj,
        successCallback: () => {
          this.loadDressColorsById({ mainDressCategoryId: this.materialId.id, pageNumber: this.page, pageSize: this.pageSize })
        },
      });
    },
    async getAllImages(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMainDressCategoryColorId(item.mainDressCategoryColorId).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImages.length; i++) {
        const image = this.getImages[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    async searchByOn(value) {
      this.searchColor = value;
      this.noSearch = false;
      await this.loadDressColorsById({
        mainDressCategoryId: this.materialId.id, name: this.searchColor, pageNumber: this.page, pageSize: this.pageSize,
      });
      this.noSearch = false
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredDressColors();
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredDressColors[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDressColors.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    async getAllImagesEdit(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMainDressCategoryColorId(item.mainDressCategoryColorId).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImages.length; i++) {
        const image = this.getImages[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = 0
    },
    matProps(color) {
      this.resetProductsByArticleNumber();
      this.$emit('click', color);
      this.classNameCh = color.colorName;
      this.inventoryProductSizeFromColorId(color.mainDressCategoryColorId);
    },
    togglePopup(colorIdd) {
      Object.keys(this.showPopup).forEach((id) => {
        if (id !== colorIdd) {
          this.$set(this.showPopup, id, false);
        }
      });

      this.$set(this.showPopup, colorIdd, !this.showPopup[colorIdd]);
    },
  },
};
</script>

<style scoped lang="scss">

.popup {
  width: 155px;
  height: 110px;
  border-radius: 8px 0px 8px 8px;
  border: 1px solid #D0D5DD;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3%;
  position: absolute;
  top: calc(100% + -22px);
  right: -25px;
  transform: translateX(-50%);
  z-index: 1;
}

.actiontd {
  position: relative;
}

.actionDelete,
.actionEdit {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionDelete {
  color: #FF274F;
  border: 1px solid #FF274F;
}

.actionEdit {
  color: #475467;
  border: 1px solid #475467;
}

.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
}
.button {
  margin-bottom: 15px !important;
  margin-top: 14px;
  // width:195px;
  // background: #FF274F !important;
  // color: #f4f1ed;
  // border-color: transparent;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}

tr:last-child {
  border-bottom: none !important;
}
 @media screen and (max-width: 1823px) {

.color-listing__wrapper2{
 width: 25% !important;
 margin-left: 10px;
   button
   {
    width:50%;
  }
  .team_table{
    width: 100% !important;
  }
 }

 .actionDelete,
.actionEdit {
  width: 100px !important;
}
 }
 @media screen and (max-width: 1623px) {

.color-listing__wrapper2{
 width: 48% !important;
 margin-left: 10px;
   button
   {
    width: 35%;
  }
  .team_table{
    width: 100% !important;
  }
 }
 }
@media screen and (max-width: 1400px) {

.color-listing__wrapper{
  margin-left: 50px;
  width: 150px !important;
  .team_table{
    width: 150px !important;
  }
}
.color-listing__wrapper2{
   margin-left: 10px;
  width: 40% !important;
  button{
width: 150px;
font-size: 13px;
height: 36px;
  }
  .team_table{
    width: 100% !important;
  }
}
}

@media screen and (max-width: 1200px) {
  .color-listing__wrapper2 {
    width: 365px !important;
  }
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
</style>
