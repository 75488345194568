<template>
  <b-sidebar
    id="add-body-measurement"
    ref="modal"
    header-class="headerModalToggle"
    right
    shadow
    no-close-on-esc
    width="fit-content"
  >
    <template #title>
      <strong style="color: #101828">{{ $t("BodyMeasurement") }}</strong>
    </template>
    <template v-slot:header-close>
      <button
        style="color: #98A2B3;;height: 60px;"
        class="fa fa-close"
      />
    </template>
    <div>
      <div
        v-if="client == 'ValdrinSahiti'"
        class="skica-container"
      >
        <b-collapse
          visible
        >
          <div
            class="front-body vs"
          >

            <div class="betweenCol">
              <div class="input">
                <span>{{ $t('toraks') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.thorax"
                  :placeholder="$t('')"
                  class="toraks1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Bust') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundBust"
                  :placeholder="$t('')"
                  class="rrethiGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('UnderBust') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundUnderBust"
                  :placeholder="$t('')"
                  class="rrethiNenGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Abdomen') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.waist"
                  :placeholder="$t('')"
                  class="beli1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Hips') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundHips"
                  :placeholder="$t('')"
                  class="vithet1"
                />
              </div>
              <div class="input">
                <span>{{ $t('BustReduction') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundLeg"
                  :placeholder="$t('')"
                  class="rrethiKembes1"
                />
              </div>
              <div class="input">
                <span>{{ $t('UnderBustReduction') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundKnee"
                  :placeholder="$t('')"
                  class="rrethiGjurit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('FrontBody') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.ankle"
                  :placeholder="$t('')"
                  class="zoguKembeve1"
                />
              </div>


            </div>
            <div class="betweenCol">


              <div class="input">
                <span>{{ $t('BackBody') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.breastReduction"
                  :placeholder="$t('')"
                  class="reniaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Shoulders') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.chestDistance"
                  :placeholder="$t('')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('ShouldersSeam') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.sleeveLength"
                  :placeholder="$t('')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Distance') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.interBust"
                  :placeholder="$t('')"
                  class="nderGjoks1"
                />
              </div>

              <div class="input">
                <span>{{ $t('ButtocksReduction') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.frontBody"
                  :placeholder="$t('')"
                  class="trupiPara1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Height') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.length1"
                  :placeholder="$t('')"
                  class="gjatesia11"
                />
              </div>
              <div class="input">
                <span>{{ $t('3QuartersLength') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.length2"
                  :placeholder="$t('')"
                  class="gjatesia21"
                />
              </div>
            </div>
            <div class="betweenCol">

              <div class="input">
                <span>{{ $t('ChanelLength') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.length3"
                  :placeholder="$t('')"
                  class="gjatesia31"
                />
              </div>
              <div class="input">
                <span>{{ $t('MiniLength') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.length4"
                  :placeholder="$t('')"
                  class="gjatesia41"
                />
              </div>
              <div class="input">
                <span>{{ $t('SleeveCircumference') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundSleeve"
                  :placeholder="$t('')"
                  class="rrethiMenges1"
                />
              </div>
              <div class="input">
                <span>{{ $t('SleeveLength') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.elbow"
                  :placeholder="$t('')"
                  class="brryli1"
                />
              </div>
              <div class="input">
                <span>{{ $t('M.3QuartersLength') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.wrist"
                  :placeholder="$t('')"
                  class="kyçi1"
                />
              </div>
              <div class="input">
                <span>{{ $t('ElbowCircumference') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.aroundNeck"
                  :placeholder="$t('')"
                  class="rrethiQafes1"
                />
              </div>
              <div class="input">
                <span>{{ $t('WristCircumference') }}</span>
                <b-form-input
                  v-model.number="bodyMeasurementDto.backBody"
                  :placeholder="$t('')"
                  class="trupiPrapa1"
                />
              </div>
            </div>
          </div>

        </b-collapse>
      </div>
      <div
        v-else
        class="skica-container"
        style="padding-top: 15px;"
      >
        <b-collapse
          :id="`collapse`"
          visible
        >
          <div
            class="btn-con"
            style="padding-left:10px"
          >
            <button
              type="button"
              class="button"
              @click="showBody = !showBody"
            >
              {{ $t('FlipBody') }}
            </button>
          </div>
          <!-- v-if="dress.showFrontBody" -->
          <div
            v-if="showBody "
            class="front-body"
          >
            <img
              src="../../../assets/images/front-body.svg"
              alt=""
            >
            <div class="toraks">
              <b-form-input
                v-model.number="bodyMeasurementDto.thorax"
                :placeholder="$t('toraks')"
                class="toraks1"
              />
              <b-form-radio
                v-model="radioStates.thorax"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.thorax === '' || bodyMeasurementDto.thorax === null"
              />
            </div>
            <div class="rrethiGjoksit">
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundBust"
                :placeholder="$t('rrethiGjoksit')"
                class="rrethiGjoksit1"
              />
              <b-form-radio
                v-model="radioStates.aroundBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.aroundBust === '' || bodyMeasurementDto.aroundBust === null"
              />
            </div>
            <div class="rrethiNenGjoksit">
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundUnderBust"
                :placeholder="$t('rrethiNenGjoksit')"
                class="rrethiNenGjoksit1"
              />
              <b-form-radio
                v-model="radioStates.aroundUnderBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.aroundUnderBust === '' || bodyMeasurementDto.aroundUnderBust === null"
              />
            </div>
            <div class="beli">
              <b-form-input
                v-model.number="bodyMeasurementDto.waist"
                :placeholder="$t('beli')"
                class="beli1"
              />
              <b-form-radio
                v-model="radioStates.waist"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.waist === '' || bodyMeasurementDto.waist === null"
              />
            </div>
            <div class="vithet">
              <b-form-radio
                v-model="radioStates.aroundHips"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundHips === '' || bodyMeasurementDto.aroundHips === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundHips"
                :placeholder="$t('RrethiVitheve')"
                class="vithet1"
              />
            </div>
            <div class="rrethiKembes">
              <b-form-radio
                v-model="radioStates.aroundLeg"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundLeg === '' || bodyMeasurementDto.aroundLeg === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundLeg"
                :placeholder="$t('rrethiKembes')"
                class="rrethiKembes1"
              />
            </div>
            <div class="rrethiGjurit">
              <b-form-radio
                v-model="radioStates.aroundKnee"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundKnee === '' || bodyMeasurementDto.aroundKnee === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundKnee"
                :placeholder="$t('rrethiGjurit')"
                class="rrethiGjurit1"
              />
            </div>
            <div class="zoguKembeve">
              <b-form-radio
                v-model="radioStates.ankle"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.ankle === '' || bodyMeasurementDto.ankle === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.ankle"
                :placeholder="$t('zoguKembeve')"
                class="zoguKembeve1"
              />
            </div>

            <div class="reniaGjoksit">
              <b-form-radio
                v-model="radioStates.breastReduction"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.breastReduction === '' || bodyMeasurementDto.breastReduction === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.breastReduction"
                :placeholder="$t('reniaGjoksit')"
                class="reniaGjoksit1"
              />
            </div>
            <div class="distancaGjoksit">
              <b-form-radio
                v-model="radioStates.chestDistance"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.chestDistance === '' || bodyMeasurementDto.chestDistance === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.chestDistance"
                :placeholder="$t('distancaGjoksit')"
                class="distancaGjoksit1"
              />
            </div>
            <div class="nderGjoks">
              <b-form-radio
                v-model="radioStates.interBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.interBust === '' || bodyMeasurementDto.interBust === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.interBust"
                :placeholder="$t('nderGjoks')"
                class="nderGjoks1"
              />
            </div>
            <div class="trupiPara">
              <b-form-radio
                v-model="radioStates.frontBody"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.frontBody === '' || bodyMeasurementDto.frontBody === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.frontBody"
                :placeholder="$t('trupiPara')"
                class="trupiPara1"
              />
            </div>
            <div class="gjatesia1">
              <b-form-input
                v-model.number="bodyMeasurementDto.length1"
                :placeholder="$t('gjatesia1')"
                class="gjatesia11"
              />
              <b-form-radio
                v-model="radioStates.length1"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length1 === '' || bodyMeasurementDto.length1 === null"
              />
            </div>
            <div class="gjatesia2">
              <b-form-input
                v-model.number="bodyMeasurementDto.length2"
                :placeholder="$t('gjatesia2')"
                class="gjatesia21"
              />
              <b-form-radio
                v-model="radioStates.length2"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length2 === '' || bodyMeasurementDto.length2 === null"
              />
            </div>
            <div class="gjatesia3">
              <b-form-input
                v-model.number="bodyMeasurementDto.length3"
                :placeholder="$t('gjatesia3')"
                class="gjatesia31"
              />
              <b-form-radio
                v-model="radioStates.length3"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length3 === '' || bodyMeasurementDto.length3 === null"
              />
            </div>
            <div class="gjatesia4">
              <b-form-input
                v-model.number="bodyMeasurementDto.length4"
                :placeholder="$t('gjatesia4')"
                class="gjatesia41"
              />
              <b-form-radio
                v-model="radioStates.length4"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.length4 === '' || bodyMeasurementDto.length4 === null"
              />
            </div>
          </div>
          <div
            v-if="!showBody"
            class="back-body"
          >
            <img
              src="../../../assets/images/back-body.svg"
              alt=""
            >
            <div class="rrethiMenges">
              <b-form-radio
                v-model="radioStates.aroundSleeve"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundSleeve === '' || bodyMeasurementDto.aroundSleeve === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundSleeve"
                :placeholder="$t('rrethiMenges')"
                class="rrethiMenges1"
              />
            </div>
            <div class="brryli">
              <b-form-radio
                v-model="radioStates.elbow"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.elbow === '' || bodyMeasurementDto.elbow === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.elbow"
                :placeholder="$t('brryli')"
                class="brryli1"
              />
            </div>
            <div class="kyçi">
              <b-form-input
                v-model.number="bodyMeasurementDto.wrist"
                :placeholder="$t('kyqi')"
                class="kyçi1"
              />
              <b-form-radio
                v-model="radioStates.wrist"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.wrist === '' || bodyMeasurementDto.wrist === null"
              />
            </div>
            <div class="rrethiQafes">
              <b-form-radio
                v-model="radioStates.aroundNeck"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.aroundNeck === '' || bodyMeasurementDto.aroundNeck === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.aroundNeck"
                :placeholder="$t('rrethiQafes')"
                class="rrethiQafes1"
              />
            </div>
            <div class="trupiPrapa">
              <b-form-radio
                v-model="radioStates.backBody"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.backBody === '' || bodyMeasurementDto.backBody === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.backBody"
                :placeholder="$t('trupiPrapa')"
                class="trupiPrapa1"
              />
            </div>
            <div class="supet">
              <b-form-input
                v-model.number="bodyMeasurementDto.shoulders"
                :placeholder="$t('supet')"
                class="supet1"
              />
              <b-form-radio
                v-model="radioStates.shoulders"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.shoulders === '' || bodyMeasurementDto.shoulders === null"
              />
            </div>
            <div class="shpina">
              <b-form-input
                v-model.number="bodyMeasurementDto.back"
                :placeholder="$t('shpina')"
                class="shpina1"
              />
              <b-form-radio
                v-model="radioStates.back"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="bodyMeasurementDto.back === '' || bodyMeasurementDto.back === null"
              />
            </div>
            <div class="reniaVitheve">
              <b-form-radio
                v-model="radioStates.declineOfTheHips"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.declineOfTheHips === '' || bodyMeasurementDto.declineOfTheHips === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.declineOfTheHips"
                :placeholder="$t('reniaVitheve')"
                class="reniaVitheve1"
              />
            </div>
            <div class="gjatesiaMenges">
              <b-form-radio
                v-model="radioStates.sleeveLength"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="bodyMeasurementDto.sleeveLength === '' || bodyMeasurementDto.sleeveLength === null"
              />
              <b-form-input
                v-model.number="bodyMeasurementDto.sleeveLength"
                :placeholder="$t('gjatesiaMenges')"
                class="gjatesiaMenges1"
              />
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        class="container"
        style="display: flex; justify-content: flex-end; margin-bottom: 35px;"
      >
        <button
          type="button"
          class="button"
          @click=" updateClientInfo()"
        >
          {{ $t("SaveAll") }}
        </button>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig'

export default {
  props: ['inventoryProductId'],
  data() {
    return {
      showBody: true,
      client: client.clientName,
      inventoryId: null,
      bodyMeasurementDto: {
        thorax: '',
        aroundBust: '',
        aroundUnderBust: '',
        waist: '',
        aroundHips: '',
        aroundLeg: '',
        aroundKnee: '',
        ankle: '',
        breastReduction: '',
        interBust: '',
        frontBody: '',
        chestDistance: '',
        length1: '',
        length2: '',
        length3: '',
        length4: '',
        aroundSleeve: '',
        elbow: '',
        wrist: '',
        aroundNeck: '',
        backBody: '',
        shoulders: '',
        back: '',
        declineOfTheHips: '',
        sleeveLength: '',
      },
      radioStates: {
        thorax: false,
        aroundBust: false,
        aroundUnderBust: false,
        waist: false,
        aroundHips: false,
        aroundLeg: false,
        aroundKnee: false,
        ankle: false,
        breastReduction: false,
        chestDistance: false,
        interBust: false,
        frontBody: false,
        length1: false,
        length2: false,
        length3: false,
        length4: false,
        aroundSleeve: false,
        elbow: false,
        wrist: false,
        aroundNeck: false,
        backBody: false,
        shoulders: false,
        back: false,
        declineOfTheHips: false,
        sleeveLength: false,
      },
    }
  },

  computed: {
    ...mapGetters(['getBodyMeasurement']),
  },
  watch: {
    getBodyMeasurement(value) {
      this.bodyMeasurementDto.thorax = value.thorax
      this.bodyMeasurementDto.aroundBust = value.aroundBust
      this.bodyMeasurementDto.aroundUnderBust = value.aroundUnderBust
      this.bodyMeasurementDto.waist = value.waist
      this.bodyMeasurementDto.aroundHips = value.aroundHips
      this.bodyMeasurementDto.aroundLeg = value.aroundLeg
      this.bodyMeasurementDto.aroundKnee = value.aroundKnee
      this.bodyMeasurementDto.ankle = value.ankle
      this.bodyMeasurementDto.breastReduction = value.breastReduction
      this.bodyMeasurementDto.interBust = value.interBust
      this.bodyMeasurementDto.frontBody = value.frontBody
      this.bodyMeasurementDto.chestDistance = value.chestDistance
      this.bodyMeasurementDto.length1 = value.length1
      this.bodyMeasurementDto.length2 = value.length2
      this.bodyMeasurementDto.length3 = value.length3
      this.bodyMeasurementDto.length4 = value.length4
      this.bodyMeasurementDto.aroundSleeve = value.aroundSleeve
      this.bodyMeasurementDto.elbow = value.elbow
      this.bodyMeasurementDto.wrist = value.wrist
      this.bodyMeasurementDto.aroundNeck = value.aroundNeck
      this.bodyMeasurementDto.backBody = value.backBody
      this.bodyMeasurementDto.shoulders = value.shoulders
      this.bodyMeasurementDto.back = value.back
      this.bodyMeasurementDto.declineOfTheHips = value.declineOfTheHips
      this.bodyMeasurementDto.sleeveLength = value.sleeveLength
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.thorax': function (newVal) {
      this.radioStates.thorax = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundBust': function (newVal) {
      this.radioStates.aroundBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundUnderBust': function (newVal) {
      this.radioStates.aroundUnderBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.waist': function (newVal) {
      this.radioStates.waist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundHips': function (newVal) {
      this.radioStates.aroundHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundLeg': function (newVal) {
      this.radioStates.aroundLeg = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundKnee': function (newVal) {
      this.radioStates.aroundKnee = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.ankle': function (newVal) {
      this.radioStates.ankle = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.breastReduction': function (newVal) {
      this.radioStates.breastReduction = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.chestDistance': function (newVal) {
      this.radioStates.chestDistance = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.frontBody': function (newVal) {
      this.radioStates.frontBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length1': function (newVal) {
      this.radioStates.length1 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length2': function (newVal) {
      this.radioStates.length2 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length3': function (newVal) {
      this.radioStates.length3 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.length4': function (newVal) {
      this.radioStates.length4 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundSleeve': function (newVal) {
      this.radioStates.aroundSleeve = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.elbow': function (newVal) {
      this.radioStates.elbow = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.wrist': function (newVal) {
      this.radioStates.wrist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.aroundNeck': function (newVal) {
      this.radioStates.aroundNeck = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.backBody': function (newVal) {
      this.radioStates.backBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.shoulders': function (newVal) {
      this.radioStates.shoulders = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.back': function (newVal) {
      this.radioStates.back = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.declineOfTheHips': function (newVal) {
      this.radioStates.declineOfTheHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'bodyMeasurementDto.sleeveLength': function (newVal) {
      this.radioStates.sleeveLength = newVal !== '' && newVal !== null;
    },
  },
  methods: {
    ...mapActions(['addCalibrationBodyMeasurement', 'calibrationBodyMeasurement']),
    updateClientInfo() {
      this.$emit('addCalibration', this.bodyMeasurementDto)
      this.$refs.modal.hide()
      // this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
}

</script>

<style scoped>
.pink-radio {
  margin-left: 6px;
}

.right {
  margin: 0px 6px 0px 0px !important;
}

.pink-radio input[type="radio"] {
  /* Change the color of the radio button itself */
  border-color: #ff0000; /* Example color (red) */
}

.pink-radio input[type="radio"]:checked {
  /* Change the color of the radio button when checked */
  background-color: #ff0000; /* Example color (red) */
}


.vs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 5%;
}

</style>
