<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    :class="$route.path.includes('products') ? 'mats-listing__wrapper2' : 'mats-listing__wrapper'"
    :style="$route.path.includes('products') ? 'display: flex; flex-direction: column; align-items: start; width: auto; margin-top: 12px;' : 'width: auto; margin-top: 59px;'"
    @click="removeLists"
  >
    <div
      style="display: flex"
      :style="$route.path.includes('products') ? '' : 'padding-bottom: 14px;'"
    >
      <div>
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchDress == ''"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchDress != '' && !noSearch"
              class="search1"
              style="position: absolute;right: 20px; top: 12px; cursor: pointer"
              @click="searchDress = ''"
            />
            <input
              v-model="searchDress"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('SearchByDress')"
              :style="getFilteredDresses.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilteredDresses.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredDresses"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result }}
              </p>
            </div>
            <!-- <div
              v-if="getFilteredDresses.length == 0"
              class="search-item"
            >
              <p>Sorry. No Results.</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; gap: 5px; align-items: center; width: 100%">
      <button
        v-if="$route.path.includes('products')"
        v-b-modal.modal-add-dress
        class="button"
      >
        <img
          src="../../assets/images/plus-circle.svg"
          alt="plus"
        >
        {{ $t('AddDress') }}
      </button>
      <vue-select
        v-model="selectedCategory"
        :options="optionsCategory"
        label="nameFiltering"
        :reduce="(e) => e.lovDressCategoryId"
        :placeholder="$t('Category')"
        aria-describedby="input-1-live-feedback"
        :clearable="false"
        style="width: 50%"
        @input="getDressesByCategory"
      />
    </div>
    <div
      class="tt"
      :style="$route.path.includes('products') ? 'width:100% !important;  padding-top: 16px; margin-top:-3px;' : ' padding-top: 16px; margin-top: 0px;'"
    >
      <table
        responsive
        class="team_table"
      >
        <thead>
          <tr>
            <th :style="$route.path.includes('products') ? 'font-size:15px; padding: 10px; border-radius: 0px 0px 0px 0px;  color:#262E6C !important;' : 'padding: 10px; border-radius: 0px 0px 0px 0px;  color:#262E6C !important; font-size: 15px;'">
              {{ $t('Dress') }}
            </th>
            <th>
              {{ $t('Category') }}
            </th>
            <th
              colspan="2"
              style="text-align: center"
            >
              {{ $t('Actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="dress in filteredDresses"
            :key="dress.id"
            class="hover-row"
            style="cursor: pointer"
            :style="classNameCh === dress.id ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(dress)"
          >
            <td>
              {{ dress.name }}
            </td>
            <td>
              {{ dress.lovDressCategoryName }}
            </td>
            <td
              class="actiontd"
              style="text-align: center;"
              @click="togglePopup(dress.id)"
            >
              <img
                src="../../assets/images/actionDots.svg"
                alt=""
              >
              <div
                v-if="showPopup[dress.id]"
                class="popup"
              >
                <div
                  v-if="$route.path.includes('products')"
                  id="edit"
                  v-b-modal.modal-edit-dress
                  @click="edit(dress)"
                >
                  <button class="actionEdit">
                    <b-icon-pencil />{{ $t('Edit') }}
                  </button>
                </div>
                <div
                  v-if="$route.path.includes('products')"
                  v-b-modal.modal-delete-dress
                  @click="getDeleteId(dress.id, dress.name)"
                >
                  <button class="actionDelete">
                    <b-icon-trash />{{ $t('Delete') }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsForDresses > 15"
          v-model="page"
          :total-rows="getTotalItemsForDresses"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-itemiii"
          next-class="next-itemiii"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>

    <AddDressModal @addDresses="addDress" />
    <EditDressModal
      :dress="dress"
      @updateDress="editDress"
    />
    <DeleteDressModal
      :name="dName"
      @deleteDressM="deleteD"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
import AddDressModal from '@/components/products/modals/AddDressModal.vue';
import EditDressModal from '@/components/products/modals/EditDressModal.vue';
import DeleteDressModal from '@/components/products/modals/DeleteDressModal.vue';



export default {
  components: {
    AddDressModal,
    EditDressModal,
    DeleteDressModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      selectedCategory: null,
      toggleSarch: false,
      noSearch: false,
      searchDress: '',
      categoryOptions: [],
      lovDressCategoryId: null,
      dressCategoryId: null,
      name: null,
      // initialId: '',
      classNameCh: '',
      deleteDressId: '',
      dName: '',
      dress: {
        created: '',
        id: '',
        name: '',
      },
      page: 1,
      pageSize: 15,
      arrowCounter: 0,
      enterPressed: false,
      showPopup: {},
    };
  },
  computed: {
    ...mapGetters(['getDressCategoryByName', 'getDressCategory', 'getFilteredDresses', 'getDresses', 'getCurrentPage', 'getTotalItemsForDresses']),
    // activeClass() {
    //   if (this.item.name === this.classNameCh) {
    //     return 'active';
    //   }
    //   return '';
    // },
    optionsCategory() {
      const valoniCategory = this.getDressCategory.map((category) => {
        return {
          name: category.name,
          nameFiltering: category.name,
          lovDressCategoryId: category.lovDressCategoryId,
        }
      });
      const nullCategory = {
        name: null,
        nameFiltering: 'All',
        lovDressCategoryId: null,
      }
      const combinedCategories = [nullCategory, ...valoniCategory];

      return combinedCategories;
    },
    filteredDresses() {
      return this.getDresses;
    },
  },
  watch: {

    // eslint-disable-next-line func-names
    // initialId() {
    //   this.immediateId(this.initialId);
    // },
    page(value) {
      this.loadDressesPagination({
        pageNumber: value,
        pageSize: this.pageSize,
        mdcName: this.searchDress,
        dressCategoryId: this.selectedCategory,
      });
    },
    searchDress(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadDressesPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            mdcName: this.searchDress,
            dressCategoryId: this.selectedCategory,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressesByName(value);
          this.noSearch = true
        }
      }, 500);
    },
  },
  mounted() {
    document.body.addEventListener('click', this.handleClickOutside);
    this.fetch();
    this.dressCategories()
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapActions(['filteredDressesByName',
      'resetFilteredDressColors',
      'loadDressesPagination',
      'addMainDressCategory',
      'editMainDressCategory',
      'loadDressColorsById',
      'resetProductsByArticleNumber',
      'resetSizes',
      'resetCustomOrders',
      'deleteMainDress',
      'changeLoadingtoTrue',
      'resetFilteredDresses',
      'resetDressColors',
      'dressCategories',
      'getDressByCategoryName',
    ]),
    async getDressesByCategory() {
      try {
        if (!this.selectedCategory) {
          await this.loadDressesPagination({
            pageNumber: this.getCurrentPage,
            pageSize: this.pageSize,
            mdcName: this.searchDress,
            dressCategoryId: null, // Fetch all dresses when category is "All"
          });
        } else {
          await this.loadDressesPagination({
            pageNumber: this.getCurrentPage,
            pageSize: this.pageSize,
            mdcName: this.searchDress,
            dressCategoryId: this.selectedCategory,
          });
        }
      } catch (error) {
        console.error('Error fetching dresses by category:', error);
      }
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)


        await this.loadDressesPagination({
          pageNumber: this.page,
          pageSize: this.pageSize,
          mdcName: this.searchDress,
          dressCategoryId: this.selectedCategory,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredDresses = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredDresses[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    edit(item) {
      this.dress.created = item.created;
      this.dress.id = item.id
      this.dress.name = item.name
      this.lovDressCategoryId = item.lovDressCategoryId
      this.$bvModal.show('modal-edit-dress');
    },
    async editDress(obj) {
      await this.editMainDressCategory({
        object: obj,
        successCallback: () => {
          this.loadDressesPagination({
            pageNumber: this.getCurrentPage, pageSize: this.pageSize, mdcName: this.searchDress, dressCategoryId: this.selectedCategory,
          });
        },
      })
    },

    async addDress(obj) {
      await this.addMainDressCategory({
        object: obj,
        successCallback: () => {
          this.loadDressesPagination({
            pageNumber: 1, pageSize: this.pageSize, mdcName: this.searchDress, dressCategoryId: this.selectedCategory,
          });
        },
      });
    },
    getDeleteId(id, name) {
      this.deleteDressId = id
      this.dName = name
    },
    async deleteD() {
      await this.deleteMainDress({
        id: this.deleteDressId,
        successCallback: () => {
          this.loadDressesPagination({
            pageNumber: this.getCurrentPage, pageSize: this.pageSize, mdcName: this.searchDress, dressCategoryId: this.selectedCategory,
          });
          this.resetDressColors()
          this.resetSizes()
          this.resetProductsByArticleNumber()
        },
      })
    },


    // immediateId(e) {
    //   this.$emit('click', e);
    // },
    matProps(dress) {
      this.resetProductsByArticleNumber();
      this.resetSizes();
      this.resetFilteredDressColors();
      this.$emit('click', dress);
      this.classNameCh = dress.id;
      try {
        // this.changeLoadingtoTrue(false)

        this.loadDressColorsById({ mainDressCategoryId: dress.id, pageNumber: 1, pageSize: this.pageSize }).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      // this.resetCustomOrders()
    },
    async searchByOn(value) {
      this.searchDress = value
      this.noSearch = false
      await this.loadDressesPagination({
        pageNumber: this.page,
        pageSize: this.pageSize,
        mdcName: this.searchDress,
        dressCategoryId: this.selectedCategory,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredDresses();
    },
    togglePopup(dressId) {
      Object.keys(this.showPopup).forEach((id) => {
        if (id !== dressId) {
          this.$set(this.showPopup, id, false);
        }
      });

      this.$set(this.showPopup, dressId, !this.showPopup[dressId]);
    },
    handleClickOutside(event) {
      const clickedElement = event.target;
      if (!clickedElement.closest('.popup') && !clickedElement.closest('.actiontd')) {
        Object.keys(this.showPopup).forEach((id) => {
          this.$set(this.showPopup, id, false);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
}

.popup {
  width: 155px;
  height: 110px;
  border-radius: 8px 0px 8px 8px;
  border: 1px solid #D0D5DD;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3%;
  position: absolute;
  top: calc(100% + -28px);
  right: -65px;
  transform: translateX(-50%);
  z-index: 1;
}

.actiontd {
  position: relative;
}

.actionDelete,
.actionEdit {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionDelete {
  color: #FF274F;
  border: 1px solid #FF274F;
}

.actionEdit {
  color: #475467;
  border: 1px solid #475467;
}
.button {
  margin-bottom: 15px !important;
  margin-top: 14px;
  // width: 195px;
  // background: #FF274F !important;
  // color: #f4f1ed;
  // border-color: transparent;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.justify-content-end{
  justify-content: flex-start !important;
}
// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}

.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 1823px) {

  .mats-listing__wrapper2{
    width: 30% !important;
  }
  .tt{
    width: 250px;
  }

}
@media screen and (max-width: 1623px) {

.mats-listing__wrapper2{
  width: 50% !important;
}
.tt{
  width: 250px;
}

}

@media screen and (max-width: 1400px) {
.mats-listing__wrapper{
  width: 150px !important;
  margin-top: 59px !important;
  margin-right: 5px;
.tt{
  width: 200px;
}
}
.mats-listing__wrapper2{
  width: 50% !important;
   button{
width: 150px;
font-size: 13px;
height: 36px;
   }
.tt{
  width: 200px;
}
 }
 input[name="search-box"] {
  display: block;
  // max-width: 500px;
  min-width: 200px;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
 }

 @media screen and (max-width: 1200px) {
  .actionDelete,
  .actionEdit {
    width: 100px !important;
  }

  .popup {
    width: 125px;
    height: 110px;
    border-radius: 8px 0px 8px 8px;
    border: 1px solid #D0D5DD;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3%;
    position: absolute;
    top: calc(100% + -28px);
    right: -28px;
    transform: translateX(-50%);
    z-index: 1;
  }

  .mats-listing__wrapper2 {
    width: 410px !important;
  }
 }

@media screen and (max-width: 896px) {
  .mats-listing__wrapper {
    width: auto !important;
    flex-basis: 49%;
  }
  .tt {
    margin-right: 0 !important;
    width: auto !important;
  }
}
.is-active {
  background-color: #dedede;
}
</style>
