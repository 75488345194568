<template>
  <div>
    <b-modal
      id="modal-delete-color"
      ref="modal"
      header-class="headerModal"
      centered
      size="l"
      :title="`${$t('DeleteD')} ${colorNameModal}?`"
      hide-footer
      @close="onCancel"
    >
      <b-form class="buttonsEverywhere">
        <b-form-group />
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"

          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="none"
          class="buttonCancel"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {

  // eslint-disable-next-line vue/require-prop-types
  props: ['colorNameModal'],
  methods: {
    onSubmit() {
      this.$emit('deleteColor')
      this.$refs.modal.hide()
    },
    onCancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
 input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
</style>
