<template>
  <div>
    <b-modal
      id="add-direct-instock"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('SubmitColor')} ${materials.name}-${colorData.colorName}-${eSize.size}`"
      hide-footer
      @close="onCancel"
    >
      <p>{{ $t('HaveInStock') }} {{ materials.name }}-{{ colorData.colorName }}-{{ eSize.size }} </p>
      <!-- v-if=" showLocation == true && showTeam == false && client != 'DrenushaXharra'" -->
      <vue-select
        v-model="location"
        :options="getLocationsByType"
        :reduce="(e) => e.locationId"
        :placeholder="$t('SelectLocation')"
        label="locationName"
        aria-describedby="input-1-live-feedback"
      />
      <span
        v-show="formSubmitted && !$v.location.required"
        style="font-size: 0.875em; color: #dc3545;"
      >
        {{ $t('LocationIsRequired') }}
      </span>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          style="margin-top: 15px; margin-right: 15px;"

          @click="onSubmit"
        >
          {{ $t('AddToStock') }}

        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-top: 15px; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AWS from 'aws-sdk';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { AWSurl } from '../../../domainConfig';


export default {
  components: {
    // Spinner,
  },
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['eSize', 'colorData', 'materials'],
  data() {
    return {
      s3: null,
      location: null,
      bucketName: AWSurl.bucketName,
      formSubmitted: false,
      form: {
        mainDressCategoryId: '',
        colorName: '',
        fileName: [],
      },
      fileData: [],
    }
  },
  validations: {
    location: {
      required,
    },
    form: {
      colorName: {
        required,
        // minLength: minLength(3),
        // alpha,
      },
    },
  },
  computed: {
    ...mapGetters([
      'getIsLoading', 'getLocationsByType',
    ]),
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions([
      'changeLoadingtoTrue', 'locations_by_type',
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onChange(event) {
      const files = event.target.files;
      if (files.length <= 3) {
        this.fileData = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileData.push(fileData);
          });

          console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    uploadFileToS3(fileData) {
      const params = {
        Bucket: AWSurl.bucketName,
        Key: `ProductPhotos/${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
        Body: fileData.fileContent,
        ContentType: fileData.filetype,
        ACL: 'public-read',
      }
      this.form.fileName.push(params.Key);
      this.s3.putObject(params, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log('File uploaded successfully to S3')
        }
      })
    },
    onSubmit() {
      this.formSubmitted = true; // Mark the form as submitted
      this.$v.$touch(); // Trigger validation

      if (this.$v.location.$invalid) {
        this.locationError = this.$t('LocationIsRequired');
      } else {
        this.$emit('AddDirectInStock', this.location);
        this.$refs.modal.hide();
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset(); }, 0);
      this.$nextTick(() => { this.$v.$reset(); });
      this.resetForm();
      this.formSubmitted = false;
      this.locationError = ''; // Clear error message on cancel
    },
    resetForm() {
      this.location = null;
      this.form.colorName = ''
      this.form.fileName = []
      this.fileData = []
    },
  },
}
</script>

<style lang="scss" scoped>
.cv {
  display: flex;
  flex-direction: column;
}
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }

.flex-form {
  display: flex;
  flex-direction: column;
}
</style>
