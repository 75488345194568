<template>
  <div>
    <!-- <Navbar />
    <Sidebar /> -->
    <section class="main product__management">
      <DressListingProduct @click="dressId" />
      <ColorListingProduct
        :material-id="dressIdShow"
        @click="colorId"
      />
      <ProductsTableProduct
        :material-id="dressIdShow"
        :color-id="colorIdShow"
        @inventoryProductId="inventoryProductIdd"
      />
      <InsideProductProduct
        :product-mat-obj="productMatObj"
        :inventory-products-of-id="inventoryProductsOfId"
        :article-number-of-product="articleNumberOfProduct"
      />
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import ColorListingProduct from '@/components/products/ColorListingProduct.vue';
import ProductsTableProduct from '@/components/products/ProductsTableProduct.vue';
import InsideProductProduct from '@/components/products/InsideProductProduct.vue';
import { mapActions, mapGetters } from 'vuex';
import DressListingProduct from '@/components/products/DressListingProduct.vue';

export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    DressListingProduct,
    ColorListingProduct,
    ProductsTableProduct,
    InsideProductProduct,
  },
  data() {
    return {
      dressIdShow: '',
      colorIdShow: '',
      inventoryProductsOfId: '',
      articleNumberOfProduct: '',
      productMatObj: {},
    };
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    // inside(item) {
    //   this.productMatObj = item;
    // },

  },
  mounted() {
    // this.loadMeasermentsById()
  },
  methods: {
    ...mapActions(['loadMeasermentsById']),
    // inside(item) {
    //   this.productMatObj = item;
    // },
    dressId(id) {
      this.dressIdShow = id;
    },
    colorId(id) {
      this.colorIdShow = id;
    },
    inventoryProductIdd(value) {
      this.inventoryProductsOfId = value.inventoryProductId
      this.articleNumberOfProduct = value.articleNumber
    },
  },
};
</script>

<style lang="scss" scoped>
.product__management .hide-on-products {
  display: none;
}
.main {
  padding: 75px 15px 15px 110px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
}
@media screen and (max-width: 1840px){
  .main{
    padding: 100px 15px 15px 100px;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
  }
  .product__management {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
  }
}
@media screen and (max-width: 896px) {
  .main {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>
