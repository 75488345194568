<template>
  <div
    :class="
      $route.path.includes('products') ? 'products-listing__wrapper2' : 'products-listing__wrapper'
    "
    style="width: 18%; display: flex; flex-direction: column; align-items: flex-start"
    :style="
      $route.path.includes('products')
        ? 'margin-top: 64px; width:auto; display: flex; flex-direction: column; align-items: flex-start;'
        : 'margin-top: 78px; width:auto; display: flex; flex-direction: column; align-items: flex-start;'
    "
  >
    <button
      v-if="$route.path.includes('products')"
      v-b-modal.modal-add-size
      class="button"
      :disabled="!colorId || getDressesSize.length == 6"
      :class="{'button--disabled': (!colorId || getDressesSize.length == 6)}"
      @click="getMaterialVByMandP"
    >
      <img
        src="../../assets/images/plus-circle.svg"
        alt="plus"
      >
      {{ $t('AddSize') }}
    </button>
    <div
      class="table__header"
      style="margin-bottom: 15px !important; margin-top: -14px"
    >
      <button
        v-if="$route.path.includes('create-order') "
        v-b-modal.add-custom-order
        class="button"
        style="border-radius: 6px; width: auto; position: relative; width: 198px; "
        @click="rr"
      >
        <b-icon-plus />
        {{ $t('AddOrder') }}
        <span
          v-if="counter != 0"
          style="font-size: 13px; background: red; border-radius: 50%; width: 20px; height: 20px; position: absolute; top: -5px; right: -9px; "
        >{{ counter == 0 ? '' : counter }}</span>
      </button>
    </div>
    <div
      style="width: 100%;  padding-top: 16px"
      :class="{'button--disabled': getDressesSize.length == 0}"
      :style="$route.path.includes('products') ? 'margin-top:-3px;' : 'margin-top: -17px'"
    >
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('Article') }}
            </th>
            <th>
              {{ $t('Description') }}
            </th>
            <th>
              {{ $t('Size') }}
            </th>
            <th>
              {{ $t('SellingPrice') }}
            </th>
            <th v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin'">
              {{ $t('PurchasePrice') }}
            </th>
            <th>
              {{ $t('Body') }}
            </th>
            <th
              v-if="$route.path.includes('products')"
            >
              {{ $t('Stock') }}
            </th>
            <th
              v-if="$route.path.includes('products')"
            >
              {{ $t('Edit') }}
            </th>
            <th
              v-if="$route.path.includes('create-order')"
            />
            <th
              v-if="$route.path.includes('products')"
            >
              {{ $t('QR-Code') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in getDressesSize"
            :key="item.inventoryProductId"
            class="hover-row"
            style="cursor: pointer;"

            :style="classNameCh === item.inventoryProductId ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(item)"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              {{ item.size }}
            </td>
            <td style="width: 17%;">
              {{ (item.price > 0 && item.price != null && item.price != '') ? numbersWithcomma(item.price) : item.price }} {{ item.currency }}
            </td>
            <td
              v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin'"
              style="width: 17%;"
            >
              {{ (item.purchasePrice > 0 && item.purchasePrice != null && item.purchasePrice != '') ? numbersWithcomma(item.purchasePrice) : 0 }} {{ item.currency }}
            </td>
            <td
              v-b-tooltip.hover
              :title="$t('ClickToSeeBody')"
              style="text-align: center;"
            >
              <img
                v-b-toggle.add-body-measurement
                src="../../assets/images/bodymeasurement.svg"
                style="width:25px;"
                @click.stop="calibration(item.inventoryProductId)"
              >
            </td>
            <td
              v-if="$route.path.includes('products')"
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer; color:#141414 !important ; font-weight: 400;'
                  : 'padding: 5px; color:#141414 !important ; font-weight: 400;'
              "
              @click="showModal"
            >
              <button
                v-b-modal.add-direct-instock
                class="editButton"
                size="sm"
                style="border: none;width:120px;height: 30px;"
                @click="AddToCustom(item), edit(item)"
              >
                <span v-if="item.added">{{ $t('Added') }}</span>
                <span v-else> {{ $t('Add') }}</span>
              </button>
            </td>
            <td
              v-if="$route.path.includes('products')"
              v-b-modal.modal-edit-size
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: center; cursor: pointer; color:#141414 !important ; font-weight: 400;'
                  : 'padding: 5px; color:#141414 !important ; font-weight: 400;'
              "
              @click="edit(item)"
            >
              <b-icon-pencil />
            </td>
            <td
              v-if="$route.path.includes('create-order')"
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer'
                  : 'padding: 5px'
              "
            >
              <button
                class="editButton"
                @click="AddToCustom(item)"
              >
                <span v-if="item.added">{{ $t('Added') }}</span>
                <span v-else> {{ $t('AddToS') }}</span>
              </button>
            </td>
            <td
              style="display:flex;gap:5px;align-items: center;"
            >
              <div
                v-b-tooltip.hover.left
                v-b-modal.qr-code
                :title="$t('QRCodeImage')"
                @click.stop="showQRCode(item.articleNumber)"
              >
                <qrcode-vue
                  ref="qrcode"
                  :value="item.articleNumber"
                  :size="30"
                  level="H"
                />
              </div>
              <b-button
                v-b-tooltip.hover.bottom
                :title="$t('DownloadQR')"
                class="buttonSubmiti"
                @click.stop="generateQRCode(item.articleNumber);"
              >
                <b-icon-download />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddSize
      :materials="materialId"
      :measure-item="measureId"
      :color-data="colorId"
      @variantMaterialData="variantMaterialData"
    />
    <AddDirectInStock
      :color-data="colorId"
      :materials="materialId"
      :measure-item="measureId"
      :e-size="eSize"
      @AddDirectInStock="createOrderInStoreTemporary"
    />
    <EditSize
      :color-data="colorId"
      :materials="materialId"
      :measure-item="measureId"
      :e-size="eSize"
      :pricess="prices"
      :materials-article-num="getMaterialsByArticleNum.productSingleMaterialVariantDtos"
      @updateSize="editSize"
    />
    <CreateOrderModal
      :added-article="rows"
      :materials="getMaterialsByArticleNum"
      @test="testt"
      @createOrder="createOrderInStore"
      @incrementCounter="increment"
      @decrementCounter="decrement"
    />
    <AddBodyMeasurement
      @addCalibration="calibrationAdd"
    />
    <QRCodeModal
      :selected-material-variant="selectedMaterialVariant"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CreateOrderModal from '@/modals/CreateOrderModal.vue';
import AddSize from '@/components/products/modals/AddSize.vue';
import AddDirectInStock from '@/components/products/modals/AddDirectInStock.vue';
import EditSize from '@/components/products/modals/EditSize.vue';
import QrcodeVue from 'qrcode.vue'
import QRCode from 'qrcode';
import AddBodyMeasurement from './modals/AddBodyMeasurement.vue';
import { client } from '../../domainConfig';
import QRCodeModal from '../QRCodeModal.vue'


export default {
  components: {
    AddSize,
    AddDirectInStock,
    CreateOrderModal,
    EditSize,
    AddBodyMeasurement,
    QrcodeVue,
    QRCodeModal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    materialId: {
      type: [String, Object],
    },
    createOrder: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    colorId: {
      type: [String, Object],
    },
    measureId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedMaterialVariant: null,
      articleNumberQrCode: null,
      initialId: '',
      materialPropId: '',
      modalVisible: false,
      client: client.clientName,
      status: true,
      size: '',
      prices: {
        price: 0,
        purchasePrice: null,
        currency: '',
      },
      eSize: {
        size: '',
      },
      classNameCh: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
      artNum: '',
      invProdId: '',
      counter: 0,
      inventoryProductsId: '',
    };
  },
  computed: {
    ...mapGetters([
      'getDressesSize',
      'getCustomOrders',
      'getMaterialsByArticleNum',
      'getResetStatus',
      'getDressColors',
      'getMeasermentsById',
      'getLoggedInUser',
    ]),
  },
  watch: {
    getResetStatus(value) {
      if (value == true) { this.rows = []; this.counter = 0; this.resetCustomOrders2() }
    },
    'materialId.name': {
      handler(value, newValue) {
        if (value != newValue) {
          this.colorId = null
        }
      },
    },
  },
  mounted() {
    // console.log('22', this.getMeasermentsById)
  },
  methods: {
    ...mapActions([
      'inventoryProductSizeFromColorId',
      'getInventoryProductsByColorId',
      'productsByArticleNumber',
      'addInventoryProductSize',
      'editInventoryProductSize',
      'addOrderInStore',
      'resetCustomOrders',
      'resetCustomOrders2',
      'getMaterialVariantsToMDCPagination',
      'resetDressColors',
      'resetSizes',
      'resetProductsByArticleNumber',
      'loadMeasermentsById',
      'addProductInStock',
      'addProductDirectInStock',
      'locations_by_type',
      'calibrationBodyMeasurement',
      'addCalibrationBodyMeasurement',
    ]),
    showQRCode(value) {
      this.selectedMaterialVariant = value;
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    calibrationAdd(value) {
      console.log('valueof', value)
      this.addCalibrationBodyMeasurement({
        inventoryId: this.inventoryProductsId,
        object: value,
        successCallback: () => {
          this.calibrationBodyMeasurement(this.inventoryProductsId)
        },
      })
    },
    calibration(value) {
      this.inventoryProductsId = value
      this.calibrationBodyMeasurement(value)
    },
    async testt(value) {
      // console.log('nammee', value);
      await this.loadMeasermentsById(value)
    },
    async generateAndDownloadQRCode() {
      const value = this.articleNumberQrCode;

      try {
        const canvas = await QRCode.toCanvas(value, { width: 300 });

        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `QRCode - ${this.articleNumberQrCode}`;
        link.click();
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    },
    generateQRCode(value) {
      this.articleNumberQrCode = value
      console.log('value', value)
      this.generateAndDownloadQRCode();
    },
    showModal() {
      this.modalVisible = true;
      this.locations_by_type('WarehouseForProductInStock')
    },
    edit(item) {
      this.prices.price = item.price
      this.prices.currency = item.currency
      this.prices.purchasePrice = item.purchasePrice
      this.eSize.size = item.size;
      this.invProdId = item.inventoryProductId;
      this.getMaterialVByMandP()
    },
    async editSize(obj) {
      await this.editInventoryProductSize({
        inventoryProductId: this.invProdId,
        object: obj,
        successCallback: () => {
          this.inventoryProductSizeFromColorId(this.colorId.mainDressCategoryColorId);
          this.productsByArticleNumber(this.artNum);
        },
      });
    },
    rr() {
      // this.loadOrderTypes()
      this.getMaterialVByMandP()
      if (this.getResetStatus == true) {
        this.rows = [];
      }
    },
    increment() {
      this.counter++;
    },
    decrement() {
      this.counter--;
    },
    async AddToCustom(item) {
      await this.productsByArticleNumber(item.articleNumber);
      const t = {
        twentyPrinciples: null,
        bodyMeasurements: {},
        itemi: item,
        pickUpDate: '',
        filledPickUpDate: true,
        materials: this.getMaterialsByArticleNum,
        probeDates: [],
        paidPrice: 0,
        price: 0,
      }
      console.log(t)
      this.rows.push(t);
      this.counter++;
      this.resetCustomOrders2();
    },
    async createOrderInStore(obj) {
      await this.addOrderInStore(obj);
      this.resetDressColors()
      this.resetSizes()
      this.resetProductsByArticleNumber()
    },
    async createOrderInStoreTemporary(value) {
      await this.productsByArticleNumber(this.artNum);
      const object2 = []
      object2.push({
        bodyMeasurements: null,
        articleNumber: this.artNum,
        materialVariantDtos: this.getMaterialsByArticleNum,
      })
      const t = {
        articleNumber: this.artNum, productInStockMaterialVariantRequestDtos: object2, message: '', size: this.size, locationId: value,
      }
      this.rows.push(t);
      await this.addProductDirectInStock(t);
      this.resetProductsByArticleNumber()
      this.classNameCh = '';
    },
    async variantMaterialData(filterObj) {
      // eslint-disable-next-line no-unused-vars
      const addInvertoryObj = {
        mainDressCategoryId: this.materialId.id,
        mainDressCategoryColorId: this.colorId.mainDressCategoryColorId,
        ...filterObj,
      };
      await this.addInventoryProductSize({
        object: addInvertoryObj,
        successCallback: () => {
          this.inventoryProductSizeFromColorId(addInvertoryObj.mainDressCategoryColorId);
        },
      });
    },

    async matProps(mat) {
      // this.$emit('inside', mat)
      this.materialPropId = mat.materialId;
      this.classNameCh = mat.inventoryProductId;
      this.size = mat.size;
      this.artNum = mat.articleNumber;
      this.$emit('inventoryProductId', mat)
      await this.productsByArticleNumber(mat.articleNumber);
    },
    async getMaterialVByMandP() {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      });
    },
  },
};
</script>

<style lang="scss">
.buttonSubmiti{
  background: #FF274F;
  border-color: white;
  color: $digit-white;
  width: 50px;
  border: transparent;
  height: 38px;
  border-radius: 6px;
}

.buttonSubmiti:hover{
  border: 2px solid #FF274F;
  background: white;
  color: #FF274F;
  transition: ease-in-out 0.3s;
  height: 38px;
  width: 50px;
}

.small:hover {
  border: 0.1px solid #FF274F !important;
  background: white;
  color: #FF274F;
  transition: ease-in-out 0.3s;
  width: 120px;
  height: 30px;
}
.modal-backdrop
{
    opacity:0.5 !important;
}
.modal-backdrop.show {
    opacity: 0.7;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.products-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
}
.button {
  margin-bottom: 15px !important;
  // width: 195.22px;
  // background: #FF274F !important;
  // color: #f4f1ed;
  // border-color: transparent;
}

.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

.team_table td {
  padding: 10px 7px !important;
}

.team_table th {
  padding: 10px;
}

.team_table th:first-child {
  border-radius: 0px 0px 0px 0px;
}

.team_table th:last-child {
  border-radius: 0px 0px 0px 0px;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .products-listing__wrapper {
    width: 250px !important;
    margin-right: 5px;
    margin-left: 5px;
  }

  // }
  .products-listing__wrapper2 {
    width: 40% !important;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
  }
}
@media screen and (max-width: 1623px) {
  .products-listing__wrapper {
    width: 250px !important;
    margin-right: 5px;
    margin-left: 5px;
  }

  // }
  .products-listing__wrapper2 {
    width: 100% !important;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
  }
}
@media screen and (max-width: 1340px){
  .products-listing__wrapper {
    width: 180px !important;
    margin-right: 5px;
    margin-left: 5px;
  }
  .products-listing__wrapper2 {
    width: 100% !important;
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media screen and (max-width: 1400px) {
  .products-listing__wrapper {
    width: 250px !important;
    margin-right: 5px;
    margin-left: 5px;
  }

  // }
  .products-listing__wrapper2 {
    width: 100% !important;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .products-listing__wrapper2 {
    width: 100% !important;
    margin-left: 10px
  }
  .buttonSubmiti {
    width:50px !important;
  }
}
@media screen and (max-width: 896px) {
  .products-listing__wrapper {
    width: auto !important;
    flex-basis: 30%;
  }
}
</style>
